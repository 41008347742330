import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import imgcons from '../../assets/gifs/ingeniero.gif';
import Footer from '../../componentes/footer/Footer';
import axios from 'axios';
import { API_URL } from '../../constants/constants';
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';
import PaginaMesesCredito from './PaginaMesesCredito';
import PaginaFinal from './PaginaFinalpago';
import Logo from '../../assets/Logo1.webp';
import Resumnpagoslist from '../../componentes/pagos/Resumnpagoslist';
import MetodoPago from '../../componentes/pagos/MetodoPago';
import DatosEnvio from '../../componentes/pagos/DatosEnvio';

const OtroMetodoPago = () => {
    const { idPedido } = useParams();
    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [dtoPedido, setDtoPedido] = useState([]);
    const [esOficinaOcurre, setEsOficinaOcurre] = useState(false);
    const [currentStep, setCurrentStep] = useState(1); // Estado para controlar el paso actual
    const [nvoMetodo, setNvoMetodo] = useState('')
    const [plazoSeleccionado, setPlazoSeleccionado] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        getPedidos(token['$udi09345'], idPedido);
    }, []);

    const [totalesDescuentos, setTotalesDescuentos] = useState({
        productosConDescuento: [],
        total: 0,
        totalsinIva: []
    });



    const getPedidos = async (idusuari, idpedido) => {
        setIsLoading(true);

        try {
            const config = {
                headers: {
                    'Authorization': token['$tk3m09s']
                },
            };

            let response = await axios.get(`${API_URL}/getOrdersByUser?user_id=${idusuari}&order_id=${idpedido}`, config);
            setDtoPedido(response.data.success);
            console.log('-------', response.data.success)
        } catch (error) {
            console.error('Error al obtener EL PEDIDO: ' + error.toString());

            // Verificar que el token aún no ha expirado
            if (error.response.data.error === 'refresh') {
                localStorage.removeItem('k-9SMD879na89dN87m');
                navigate('/');
                return;
            }
        }

        setIsLoading(false);
    };

    const enviarPago = (metodopago) => {
        // console.log('El método es:',);
        setPlazoSeleccionado([])
        setNvoMetodo(metodopago)
        setCurrentStep(2); // Cambiar al paso 2
    };

    const calcularTotalIndividual = (carmodificado = null) => {
        const auxProducts = dtoPedido.productos
        return auxProducts?.map(producto => ({
            sku: producto.sku,
            totalIndividual: producto.precio.cantidad
        }));
    };
    let totalIndividual = calcularTotalIndividual()


    const creditoContado = (metodopago) => {
        const totalFinalDto = dtoPedido.total + dtoPedido.envio;
        console.log('totelsd', totalFinalDto, totalEnvioFinal)
        // console.log('total padrino', total)
        setNvoMetodo('credito');
        const plazo = {
            meses: "1",
            precioMensual: totalFinalDto,
            precioFinal: totalFinalDto,
            msi: false
        };
        setPlazoSeleccionado(plazo);

    }

    const enviarMeses = (metodopago) => {
        // console.log('Se va a ver los meses con tarjeta de crédito', metodopago);

        if (metodopago === 'creditocontado') {
            console.log('chafeo')
            setNvoMetodo('credito'); // Establece el método de pago como 'credito' si es 'creditocontado'
        } else {
            setNvoMetodo(metodopago);

        }

        setCurrentStep(3); // Cambiar al paso 2
    };

    const handleBack = () => {
        if (currentStep === 2) {
            setCurrentStep(1);
        } else {
            navigate('/Pedidos');
        }
    };



    const saveLink = async (link) => {
        try {

            const config = {
                headers: {
                    'Authorization': token['$tk3m09s']
                }
            };

            const formdata = {
                no_pedido:  String(idPedido),
                link_pago: 'linkdelete'
            }

             console.log('lo que amndere para borrar', formdata)

            const response = await axios.put(API_URL + '/updateOrder', formdata, config);
            console.log('se guardo tu ', response)


        } catch (error) {
            console.error('Ha ocurrido un error al obtener direcciones: ' + error.toString());
        }finally{
            updateOrder()
        }
    }

    const saveOnlyLink = async (orderId, link) => {
        try {
            const config = {
                headers: {
                    'Authorization': token['$tk3m09s']
                }
            };
            const formdata = {
                no_pedido: orderId,
                link_pago: link
            }

            const response = await axios.put(API_URL + '/updateOrder', formdata, config);
            return true
        } catch (error) {
            console.error('Ha ocurrido un error al obtener direcciones: ' + error.toString());
            return false
        }
    }


    const updateOrder = async () => {
        // console.log('actulizare el pedido nevo metoros es:', nvoMetodo)
        setIsLoading(true);


        try {
            const config = {
                headers: {
                    'Authorization': token['$tk3m09s']
                },
            };

            let formDataToSend;
            if (nvoMetodo === 'credito') {
                formDataToSend = {
                    "id_orden": String(idPedido),
                    "metodo_pago": nvoMetodo,
                    "total_productos": plazoSeleccionado.precioFinal - dtoPedido.total_envio,
                    "total": plazoSeleccionado.precioFinal,
                    "mensualpay": plazoSeleccionado.costo ? plazoSeleccionado?.costo : plazoSeleccionado?.precioMensual,
                    "msi": plazoSeleccionado.meses
                };
            } else {
                formDataToSend = {
                    "id_orden": String(idPedido),
                    "metodo_pago": nvoMetodo
                };
            }
            console.log('Datos a actualizar: ', formDataToSend)

            let response = await axios.post(`${API_URL}/updateOrder`, formDataToSend, config);
            // console.log(response.data.success)
            getPedidos(token['$udi09345'], idPedido);

        } catch (error) {
            console.error('Error al obtener EL PEDIDO: ' + error.toString());

            // Verificar que el token aún no ha expirado
            if (error.response.data.error === 'refresh') {
                localStorage.removeItem('k-9SMD879na89dN87m');
                navigate('/');
                return;
            }
        }

        let order_data = {
            cliente: token['$name'],
            email: token['$email'],
            ocurre: dtoPedido.ocurre,
            factura: dtoPedido.factura,
            total_envio: dtoPedido.total_envio,
            total: dtoPedido.total,
            total_productos: dtoPedido.total_productos,
        };
        let datosParaEnviar = {
            // sale_order_id: success, // NOTE: Se agrega despúes
            factura: dtoPedido.factura,
            id_usuario: token['$udi09345'],
            datos_orden: order_data,
            carrito_usuario: dtoPedido.productos,
            direccion_usuario: dtoPedido.direccion_entrega,
            metodo_pago: dtoPedido.metodo_pago,
            pk: '',
            office_id: '',
            office_name: '',
            sale_order_id: dtoPedido.no_orden_odoo
        };

        if (nvoMetodo === 'mercadoPago') {
            const config = {
                headers: {
                    'Authorization': token['$tk3m09s']
                },
            };
            // Crear preferencias de Mercado Pago
            const response = await axios.post(`${API_URL}/createPreferenceMP`, {
                products: dtoPedido.productos,
                orderId: idPedido,
                totalShipping: dtoPedido.total_envio,
                email: token['$email'],
                address: {
                    zip_code: dtoPedido?.direccion_entrega?.cp
                }
            }, config);

            const data = response.data;
            if (data.id) {
                // Guarda link para pagar despues
                const response = await saveOnlyLink(idPedido, data.init_point);

                // Redirigir a la página de Mercado Pago
                window.location.href = data.init_point;
            }
            return
        }

        // Si el método seleccionado es "credito", ajusta los valores y añade 'msi'
        if (nvoMetodo === 'credito') {
            // console.log('El metodo es credito agregare ')
            const total = plazoSeleccionado.precioFinal;  // Asume que plazoSeleccionado.precioFinal es un número válido
            const total_productos = plazoSeleccionado.precioFinal - dtoPedido.total_envio;

            // Actualizar el total y total_productos en order_data
            order_data.total = parseFloat(total);
            order_data.total_productos = parseFloat(total_productos);

            order_data.mensualpay = parseFloat(plazoSeleccionado.precioMensual);  // Asume que plazoSeleccionado.meses es un número válido
            // Añadir 'msi' a order_data
            order_data.msi = parseInt(plazoSeleccionado.meses);  // Asume que plazoSeleccionado.meses es un número válido

            // console.log('la data actulizada es ', order_data)
        }



        if (nvoMetodo === 'oxxo') {

            // console.log('los datos son Oxxo', nvoMetodo, idPedido)
            // console.log('pedido oxxo', datosParaEnviar)

            setIsLoading(false);

            navigate(`/Pago-Oxxo/${idPedido}`, { state: { token: nvoMetodo, id: idPedido, datospadso: datosParaEnviar } });

        } else if (nvoMetodo === 'transferencia') {
            // console.log('los datos son transferencia', nvoMetodo, idPedido)
            // console.log('pedido transferencia', dtoPedido)
            setIsLoading(false);
            navigate(`/Pago-Tranferencia/${idPedido}`, { state: { token: nvoMetodo, id: idPedido, datospadso: datosParaEnviar } });


        } else {
            // Redirige a la ruta para agregar tarjeta con el token y el ID
            // console.log('los datos son tarjeta', nvoMetodo, idPedido)
            // console.log('pedido tarjeta', dtoPedido)
            setIsLoading(false);

            navigate('/pago-tarjeta', { state: { token: nvoMetodo, id: idPedido, datospadso: datosParaEnviar } });  //se envia el metodo(debito o credito) el numero de pedido y los datos del carrito para mostrar un resusmen opcional 

        }
    }


    const enviarPagofinal = (plazo) => {

        setPlazoSeleccionado(plazo)
        console.log('lo que seleeciono fue ', plazo)
        setCurrentStep(2); // Cambiar al paso 2

    }


    const calcularTotalSinIva = (carmodificado = null) => {
        const auxProducts = dtoPedido.productos
        return auxProducts?.map(producto => ({
            sku: producto.sku,
            totalSinIva: producto.precio_sin_iva.cantidad,
            descuento: producto.descuento
        }));
    };
    let totalsinIva = calcularTotalSinIva()

    return (
        <div>
            {isLoading && <CargandoSpinner txtcharge="Cargando Datos" />}




            <header className="flex items-center justify-between p-4" style={{ borderBottom: '1px solid #152a6e' }}>
                <div className="w-16 ">
                    <button onClick={handleBack} className="text-[#152a6e] flex"
                        name='btn-handleBack'
                        id='btn-handleBack'>
                        <i className="fas fa-chevron-left text-2xl"></i>
                    </button>


                </div>
                <div className="flex-grow flex justify-center">
                    <img width="300" height="200"
                        src={Logo} alt="Logo" className="h-10 w-auto lg:h-10" />
                </div>
                <div className="w-16">
                </div>
            </header>

            <main className="flex-grow">
                <div className="flex py-2 flex-col min-h-screen bg-gray-100 px-2">
                    <div className="flex items-center  xl:w-4/5 mx-auto justify-between px-4 py-2">
                        <h1 className="text-lg  xl:text-2xl font-bold text-left">
                            {currentStep === 1 ? 'Selecciona un nuevo método de pago' : 'Confirmación de nuevo método'}
                        </h1>
                        <div></div>
                    </div>
                    <div className="lg:w-full">
                        {(() => {
                            switch (currentStep) {
                                case 1:
                                    return (
                                        <div className='xl:w-4/5 mx-auto'>
                                            <div className="px-4 py-1">
                                                <p>Pedido N° <span className="font-black">{idPedido}</span></p>
                                            </div>


                                            <PaginaFinal
                                                totalpago={dtoPedido.total}
                                                carts={dtoPedido.productos}
                                                datosPerfil={token}
                                                totalenvio={dtoPedido.total_envio}
                                                direcciondeenvio={dtoPedido.direccion_entrega}
                                                requierefactura={dtoPedido.lengthfactura}
                                                ocurre={esOficinaOcurre}
                                                enviarPago={enviarPago}
                                                enviarMeses={enviarMeses}

                                                creditoContado={creditoContado}
                                                totalesDescuentos={dtoPedido.total}
                                                apagarDescuento={true}

                                                otrometodo={true}
                                            />

                                        </div>
                                    );

                                case 2:
                                    return (
                                        <div className='xl:w-4/5 mx-auto'>
                                            <div className="px-4 py-1">
                                                <p>Pedido N° <span className="font-black">{idPedido}</span></p>
                                            </div>

                                            <div className='lg:flex w-full '>
                                                <div className='xl:w-2/3 lg:w-1/2'>
                                                    <Resumnpagoslist listaCar={dtoPedido.productos} />

                                                    {plazoSeleccionado && Object.keys(plazoSeleccionado).length !== 0 ? (
                                                        // Si plazoSeleccionado es un arreglo con datos, mostrar el div alternativo
                                                        <div className="hidden lg:flex lg:flex-col lg:items-end px-8 lg:pr-24">

                                                            <div className="flex  justify-between w-full pb-1 border-b-2 border-gray-300">
                                                                <p className="text-[#030303] font-black text-base mr-2">
                                                                    Total de pedido
                                                                </p>
                                                                <h2 className="text-[#012a45] text-sm">
                                                                </h2>
                                                            </div>
                                                            <div className="flex  justify-between w-full mt-2">
                                                                <p className="text-[#757575] text-base mr-2">Total de productos</p>
                                                                <h2 className="text-[#012a45] text-base">
                                                                    ${dtoPedido.total_productos.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </h2>
                                                            </div>
                                                            <div className="flex items-center justify-between w-full  mt-1 ">
                                                                <p className="text-[#757575] text-base mr-2">Costo de envío</p>
                                                                <h2 className="text-[#030303] text-base">

                                                                    {
                                                                        dtoPedido.total_envio > 0 ?
                                                                            `$${dtoPedido.total_envio.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
                                                                            'Envío Gratis'
                                                                    }
                                                                </h2>
                                                            </div>


                                                            <div className="flex items-center justify-between w-full mt-2">
                                                                {/* Contenido adicional que deseas mostrar cuando plazoSeleccionado está presente */}
                                                                <p className="text-[#757575] text-base mr-2">Subtotal</p>
                                                                <h2 className="text-[#030303] text-base">
                                                                    $ {dtoPedido.total.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </h2>
                                                            </div>


                                                            <div className="flex items-center border-y-2 py-2 border-gray-200 justify-between w-full mt-2">
                                                                {/* Contenido adicional que deseas mostrar cuando plazoSeleccionado está presente */}
                                                                <p className="text-[#757575] text-base mr-2">Pagas</p>
                                                                <h2 className="text-[#030303] text-base">

                                                                    {plazoSeleccionado.meses}x ${plazoSeleccionado.precioMensual?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                                                                </h2>
                                                            </div>



                                                            <div className="flex items-center justify-between w-full mb-16">
                                                                {/* Contenido adicional que deseas mostrar cuando plazoSeleccionado está presente */}
                                                                <p className="text-[#474747] text-base mr-2 font-semibold">Total</p>
                                                                <h2 className="text-black text-lg font-semibold">

                                                                    $ {plazoSeleccionado.precioFinal?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                                                                </h2>
                                                            </div>




                                                        </div>





                                                    ) : (

                                                        <div className="hidden lg:flex lg:flex-col  lg:items-end px-8 lg:pr-24">
                                                            <div className="flex  justify-between w-full pb-1 border-b-2 border-gray-300">
                                                                <p className="text-[#030303] font-black text-base mr-2">
                                                                    Total de pedido
                                                                </p>
                                                                <h2 className="text-[#012a45] text-sm">
                                                                </h2>
                                                            </div>

                                                            <div className="flex  justify-between w-full mt-2">
                                                                <p className="text-[#757575] text-base mr-2">Total de productos</p>
                                                                <h2 className="text-[#012a45] text-base">
                                                                    ${dtoPedido.total_productos.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </h2>
                                                            </div>
                                                            <div className="flex items-center justify-between w-full  mt-1 ">
                                                                <p className="text-[#757575] text-base mr-2">Costo de envío</p>
                                                                <h2 className="text-[#030303] text-base">

                                                                    {
                                                                        dtoPedido.total_envio > 0 ?
                                                                            `$${dtoPedido.total_envio.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
                                                                            'Envío Gratis'
                                                                    }
                                                                </h2>
                                                            </div>

                                                            <div className="flex items-center justify-between w-full  mt-2 ">
                                                                <p className="text-[#474747] text-base mr-2 font-semibold">Total a pagar</p>
                                                                <h2 className="text-black text-lg font-semibold">
                                                                    $ {dtoPedido.total.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                                <div className='xl:w-1/3  lg:w-1/2'>
                                                    <DatosEnvio direcciondeenvio={dtoPedido.direccion_entrega} />

                                                    <MetodoPago mtdopago={nvoMetodo} title='Nuevo método seleccionado' />
                                                </div>
                                            </div>



                                            <div className="flex flex-col justify-center xl:w-4/5 bg-white border-t-2 border-gray-300 fixed bottom-0 w-full py-2">
                                                <div>
                                                    {plazoSeleccionado && Object.keys(plazoSeleccionado).length !== 0 ? (
                                                        // Si plazoSeleccionado es un arreglo con datos, mostrar el div alternativo
                                                        <div >

                                                            <div className="flex items-center justify-between w-80 mx-auto lg:hidden">
                                                                <p className="text-[#757575] text-sm mr-2">Pagas</p>
                                                                <h2 className="text-[#012a45] text-base">{plazoSeleccionado.meses}x ${plazoSeleccionado.precioMensual?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h2>
                                                            </div>

                                                            <div className="flex items-center justify-between w-80 mx-auto mt-2 lg:hidden">
                                                                <p className="text-[#757575] text-md mr-2">Total:</p>
                                                                <h2 className="text-[#012a45] text-lg font-semibold">
                                                                    $ {plazoSeleccionado.precioFinal?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </h2>
                                                            </div>




                                                        </div>
                                                    ) : (
                                                        // Si plazoSeleccionado no es un arreglo con datos, mostrar el div original
                                                        <div>
                                                            <div className="flex items-center justify-between w-80 mx-auto lg:hidden ">
                                                                <p className="text-[#757575] text-sm mr-2">Productos:</p>
                                                                <h2 className="text-[#012a45] text-sm">${dtoPedido.total_productos.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h2>
                                                            </div>
                                                            <div className="flex items-center justify-between w-80 mx-auto mt-1 lg:hidden">
                                                                <p className="text-[#757575] text-sm mr-2">Envio:</p>
                                                                <h2 className="text-[#030303] text-sm">
                                                                    {dtoPedido.total_envio > 0 ?
                                                                        `$${dtoPedido.total_envio.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
                                                                        'Envío Gratis'
                                                                    }
                                                                </h2>
                                                            </div>
                                                            <div className="flex items-center justify-between w-80 mx-auto mt-2 lg:hidden">
                                                                <p className="text-[#757575] text-md mr-2">Total:</p>
                                                                <h2 className="text-[#012a45] text-lg font-semibold">
                                                                    $ {dtoPedido.total?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>



                                                <div className='flex justify-center mt-2'>

                                                    <button
                                                        onClick={saveLink}
                                                        name='btn-updateOrder'
                                                        id='btn-updateOrder'
                                                        className='py-2 px-10 lg:px-10 lg:py-1 lg:text-base text-black uppercase rounded font-bold font-roboto transition-colors duration-300 bg-[#e9bf56] hover:bg-[#1e3d8b] hover:text-white'



                                                    >
                                                        Confirmar y pagar
                                                    </button>

                                                </div>
                                            </div>


                                        </div>
                                    );
                                case 3: return (
                                    <div className='lg:w-full '>

                                        <PaginaMesesCredito
                                            totalesDescuentos={totalesDescuentos}

                                            totalpago={dtoPedido.total}
                                            totalenvio={dtoPedido.total_envio}
                                            mtdopago={nvoMetodo}
                                            totalsinIva={totalsinIva}

                                            enviarPagofinal={enviarPagofinal}
                                            losMsison={dtoPedido.msitiene}
                                            totalIndividual={totalIndividual}

                                        />

                                    </div>
                                );


                                default:
                                    return null;
                            }
                        })()}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default OtroMetodoPago;
