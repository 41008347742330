import React, { useState, useEffect } from 'react';
import CardEntrega from '../cards/CardEntrega';
import Formulario2 from '../../componentes/formularios/Formulario2';
import ModalOcurrePro from '../modales/ModalOcurredos';
import axios from 'axios';
import CargandoSpinner from '../cargacomponetes/CargandoSpinner';
import AlertaWarning from '../alertas/AlertaWarning';
import ModalFormAutoZone from '../modales/ModalFormAutoZone ';
import { API_URL } from '../../constants/constants';

function DireccionPrincipal(props) {

  const {
    direccion,
    onAgregarDireccion,
    ocurredirecto,
    direccionprincial,
    carts,
    manejarTotalEnvio } = props;


  const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;

  const [modalOcurrePro, setModalOcurrePro] = useState(false);
  const [mostrarPrincipal, setMostrarPrincipal] = useState(true);
  const [direccionSeleccionada, setDireccionSeleccionada] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingShipping, setLoadingShipping] = useState(false);  
  const [showAlertWarning, setShowAlertWarning] = useState(false);
  const [temporalAddress, setTemporalAddress] = useState([]);

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const actualizar = () => {
    window.location.reload();
  };

  const btneditaragretar = () => {
    setMostrarPrincipal(false);
  };

  const openOcurrepro = () => {
    setModalOcurrePro(true);
  };

  const handleCloseOcurre = () => {
    setModalOcurrePro(false);
  };

  const direccionPrincipal = direccion.find(direccion => direccion.principal);
  let cpPrincipal = direccionPrincipal ? direccionPrincipal.cp : 'No se encontró dirección principal';
  const storedCodigoPostal = localStorage.getItem('codigoPostal');

  const cotizarDirecto = (datos) => {
    const cp = cpPrincipal || storedCodigoPostal || '42186';
    const colonia = 'Sucursal Ocurre ' + datos.sucursalOcurreSeleccionada.label;

    datosocurre(cp, colonia, datos.sucursalOcurreSeleccionada.value);

    setModalOcurrePro(false);
  };

  const datosocurre = async (cp, colonia, idoficna) => {
    setLoading(true);


    const skusExcluidos = ['HNQR9KIT3', 'HTCRJ01']; // HNTP1N eliminado de los excluidos

    let cantidadMotor = 0;
    let cantidadPluma = 0
    const skus = [];

    carts.forEach(item => {
      if (item.t_envio !== 'estafeta' && !skusExcluidos.includes(item.sku)) {
        if (item.sku === 'HNTP1N') {

          skus.push('HNTP1', 'HNTP100');
          cantidadPluma += item.cantidad
        } else {
          skus.push(item.sku);
        }
      }

      if (item.categoria === 'Rampas' && !skusExcluidos.includes(item.sku)) {
        cantidadMotor += item.cantidad;
      }
    });

    const necesitaMotor = carts.some(item => item.categoria === 'Rampas' && !skusExcluidos.includes(item.sku));

    if (necesitaMotor) {
      skus.push('MOTOR');
    }

    //console.log('los skus a enviar son', skus);
    //console.log('cantidad pluma', cantidadPluma);
    try {
      const promises = skus.map(sku => axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/getProductsBySku', { sku }));

      const responses = await Promise.all(promises);

      const productosDataTemp = responses.flatMap(response =>
        response.data.map(producto => {
          const itemCarrito = carts.find(item => item.sku === producto.sku);
          if (producto.sku === 'MOTOR') {
            return { ...producto, no_piezas: cantidadMotor };
          }
          if (producto.sku === 'HNTP1' || producto.sku === 'HNTP100') {
            if (itemCarrito) {
              return { ...producto, no_piezas: itemCarrito.cantidad + cantidadPluma };
            }
            return { ...producto, no_piezas: cantidadPluma };
          }

          return itemCarrito ? { ...producto, no_piezas: itemCarrito.cantidad } : producto;
        })
      );

      const tieneEnvioEstafeta = carts.some(item => item.t_envio === 'estafeta');

      if (productosDataTemp.length === 0) {
        manejarTotalEnvio(tieneEnvioEstafeta ? 0 : (carts[0]?.id_producto == 153 ? 0 : 1500), direccionSeleccionada, colonia, idoficna, cp, temporalAddress, cantidadMotor); //envio directo
        setLoading(false);
      } else {

        console.log('ya con la pluams', productosDataTemp)
        enviardatosOducrreDirecto(productosDataTemp, cp, colonia, idoficna, cantidadMotor);
      }

    } catch (error) {
      console.error("Error al obtener los productos con los SKU: no aplica ocurre", error);
    } finally {
      setLoading(false);
    }
  };

  const enviardatosOducrreDirecto = async (lstaprsku, cp, colonia, idoficna, cantidadMotor) => {
    setLoadingShipping(true)
    const sumaValorDeclarado = lstaprsku.reduce((acumulador, producto) => {
      const valorDeclarado = Number(producto.valor_declarado);
      return acumulador + (isNaN(valorDeclarado) ? 0 : valorDeclarado);
    }, 0);
    const storedCodigoPostal = cp;
    const storedNeighborhood = colonia;

    const nuevoArreglo = lstaprsku.map(producto => ({
      alto: producto.alto_cm,
      ancho: producto.ancho_cm,
      cantidad: producto.no_piezas,
      contenido: producto.contenido,
      largo: producto.largo_cm,
      peso: producto.peso
    }));

    let email = null

    if (token){
      email = token["$email"]
    }

    const formatParcel = lstaprsku.map(producto => ({
      "address": {
          "postal_code": storedCodigoPostal,
          "state": "-",
          "city": "-",
          "colony": storedNeighborhood,
          "street": "-",
          "email": email
      },
      "parcel": {
          "length": producto.largo_cm,
          "width": producto.ancho_cm,
          "height": producto.alto_cm,
          "weight": producto.peso,
          "quantity": producto.no_piezas
      },
      "carriers": []
    }));

    const dataConIdOffice = {
      calle: '',
      noExterior: '',
      colonia: storedNeighborhood,
      ciudad: '',
      estado: '',
      ...(idoficna && { idOffice: idoficna, ocurre: "true" }),
      cp: storedCodigoPostal,
      valorDeclarado: 16000,
      paquetes: nuevoArreglo,
    };

    // console.log('enviare a cotizar', dataConIdOffice);
    console.log("Nuevo con sky", formatParcel)
    try {
      // Peticion a castores
      const responseCastores = await axios.post('https://backend-chatify-sjkbu6lfrq-uc.a.run.app/cotEnvio', dataConIdOffice);
      
      // Peticion a Skydropx
      const promises = formatParcel.map(element => {
        return axios.post(`${API_URL}/getQuotation`, element);
      });
      
      const responses = await Promise.all(promises);
      let totalShipping = 0
      responses.forEach((response, index) => {
        console.log(`Respuesta para elemento ${index}:`, response.data.data?.total);
        if (response?.data?.data?.total) {
          totalShipping += response.data.data?.total * response.data.data?.quantity        
          console.log("Acumulado: ", totalShipping)  
        }
      });

      totalShipping =  parseFloat(totalShipping.toFixed(2))

      await sleep(7)

      const response = {
        data: {
          TOTAL: null
        }
      }

      console.log("Response castores")
      console.log(responseCastores.data)

      console.log("El total del envio es: ", totalShipping)
      console.log("Type", typeof(totalShipping))
     // console.log('respuest ocurre', response.data);
      if (typeof responseCastores === "object" && responseCastores.data?.TOTAL && totalShipping == 0) {
        const totalCastores = responseCastores.data.TOTAL;
        totalShipping = totalCastores
      }

      if (totalShipping == 0) {
        setLoadingShipping(false);
        if (!idoficna) {
          setModalOcurrePro(true);
        } else {
          abrirWarningModal();
        }
      } else {
        manejarTotalEnvio(totalShipping, direccionSeleccionada, storedNeighborhood, idoficna, storedCodigoPostal, temporalAddress, cantidadMotor);
        setLoadingShipping(false);
      }
    } catch (error) {
      setLoadingShipping(false)
      console.error("Error al enviar los datos con Ocurre directo:", error);
      abrirWarningModal();
    }
  };

  const handleSeleccionarDireccion = (Direccion, dtostemporal) => {
    setDireccionSeleccionada(Direccion);

    if (dtostemporal && Object.keys(dtostemporal).length !== 0) {
      setTemporalAddress(dtostemporal);
    }
  };

  useEffect(() => {
    if (direccionSeleccionada) {
      datosocurre(direccionSeleccionada.cp, direccionSeleccionada.colonia);
    }
  }, [direccionSeleccionada]);

  const abrirWarningModal = () => {
    setShowAlertWarning(true);
  };

  const closeAlertWarning = () => {
    setShowAlertWarning(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className='mb-10'>
      {loading && <CargandoSpinner txtcharge="Calculando envío a dirección seleccionada" />}
      {loadingShipping && <CargandoSpinner txtcharge="Calculando envío a dirección seleccionada" />}

      <ModalFormAutoZone isOpen={isModalOpen} onClose={handleCloseModal} onSeleccionarDireccion={handleSeleccionarDireccion} />

      {showAlertWarning && (
        <AlertaWarning
          onClose={closeAlertWarning}
          titleAlert="Algo salió mal"
          conteAlert="Por favor, verifica tus datos e inténtalo de nuevo."
        />
      )}

      {modalOcurrePro && <ModalOcurrePro closeocurre={handleCloseOcurre} cotizarDirecto={cotizarDirecto} />}

      {mostrarPrincipal && direccionprincial.length === 0 && direccion.length === 0 ? (
        <div className='flex  justify-center'>
          <Formulario2 title1={'Agregar'} title2={'Domicilio'} goform={actualizar} />
        </div>
      ) : mostrarPrincipal && direccionprincial.length > 0 ? (
        <div>
          <div className='overflow-x-auto '>
            {direccionprincial.map((dir) => (
              <div className="min-w-[calc(25%-1rem)] mx-auto lg:min-w-0 lg:w-4/5 p-2" key={dir.id_direccion}>
                <CardEntrega
                  direccion={dir}
                  title='Enviar a domicilio '
                  showAddButton={false}
                  onSeleccionar={() => handleSeleccionarDireccion(dir)}
                  buttoneditaroagregar={btneditaragretar}
                />
              </div>
            ))}
          </div>

          {ocurredirecto && <div className='px-7 lg:mt-4 lg:w-4/5 mx-auto cursor-pointer' onClick={openOcurrepro}>
            <div className='w-full p-4 mx-auto flex rounded-md justify-between p-2 border border-[#c2c2c2]'>
              <h2 className='text-[#000000] font-medium'>Retirar en ocurre Castores</h2>
              <i className="fas fa-chevron-circle-down text-xl text-[#757575]"></i>
            </div>
          </div>}
        </div>
      ) : (
        <div>
          <div className='  overflow-x-auto '>
            {direccion.map((dir) => (
              <div className="min-w-[calc(25%-1rem)] mx-auto lg:min-w-0 lg:w-1/2 p-2" key={dir.id_direccion}>
                <CardEntrega
                  direccion={dir}
                  title='Enviar a domicilio '
                  showAddButton={false}
                  onSeleccionar={() => handleSeleccionarDireccion(dir)}
                />
              </div>
            ))}
          </div>

          {ocurredirecto && <div className='px-8 lg:mt-4 lg:w-1/2 mx-auto cursor-pointer' onClick={openOcurrepro}>
            <div className='w-full p-5 mx-auto flex rounded justify-between p-2 border border-[#c2c2c2]'>
              <h2 className='text-[#000000] font-medium'>Retirar en ocurre Castores</h2>
              <i className="fas fa-chevron-down"></i>
            </div>
          </div>}

          <div className='flex justify-center'>
            {
              token && token['$r07sad'] === 'Tienda' ? (
                <button onClick={handleOpenModal}
                  name='btn-handleOpenModal'
                  id='btn-handleOpenModal' className='bg-[#e9bf56] py-2 px-5 mt-5 rounded uppercase'>Enviar a otra dirección</button>
              ) : (
                <button onClick={onAgregarDireccion}
                  name='btn-onAgregarDireccion'
                  id='btn-onAgregarDireccion' className='bg-[#e9bf56] py-2 px-5 mt-5 rounded uppercase'>Agregar dirección</button>
              )
            }
          </div>
        </div>
      )}
    </div>
  );
}

export default DireccionPrincipal;
