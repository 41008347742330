import React, { useState, useEffect } from 'react';
import Metodopago from '../../componentes/pagos/Metodpago';
import DiscountModal from '../../componentes/modales/ModalDescueto';

function PaginaFinal({ totalpago, carts, totalenvio, enviarPago, enviarMeses, otrometodo, creditoContado, aplicarDescuento, totalesDescuentos, apagarDescuento }) {

    // Imprime los totales de descuento cuando cambian
    useEffect(() => {
        if (totalesDescuentos) {
            // console.log('los totales son:', totalesDescuentos);
        }
    }, [totalesDescuentos]);

    // Contenido de tu página final
    const [nuevoMetodo, setNuevoMetodo] = useState(null);
    const [discountApplied, setDiscountApplied] = useState(false);
    const [discountDetails, setDiscountDetails] = useState(null);

    const onMetodosleecChange = (metodoSeleccionado) => {
        setNuevoMetodo(metodoSeleccionado); // Actualiza el estado con el nuevo método
    }

    const onTarjetaSeleccionadaChange = (nuevaTarjetaSeleccionada) => {
        if (nuevaTarjetaSeleccionada) {
            //setTarjetaSeleccionada(nuevaTarjetaSeleccionada);
            //gopay(nuevaTarjetaSeleccionada.tipoTarjeta, nuevaTarjetaSeleccionada.token);
        }
    };

    const totalDescuento = totalesDescuentos?.totaldescuento ?? totalpago;
    const totalpedido = totalDescuento + totalenvio;

    const handlePayment = () => {
        if (nuevoMetodo) {
            // Lista de métodos permitidos
            const metodosPermitidos = ['oxxo', 'transferencia', 'credito', 'debito', 'creditocontado'];
            // Verificar si el nuevo método está en la lista de métodos permitidos
            if (metodosPermitidos.includes(nuevoMetodo)) {
                // console.log('El método seleccionado es válido, procediendo a ejecutar gopay');
                //    console.log('el metodoSeleccionado by el paso es', nuevoMetodo)

                // Verificar si el método seleccionado es 'credito'
                if (nuevoMetodo === 'credito' || nuevoMetodo === 'creditocontado') {
                    // Si es 'credito', llamar a enviarMeses
                    enviarMeses(nuevoMetodo);
                } else {
                    // Para cualquier otro método válido, llamar a enviarPago
                    enviarPago(nuevoMetodo);
                }
            } else {
                // 'El método seleccionado no es válido, no se ejecutará gopay
            }
        }
    };

    const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);

    const handleOpenDiscountModal = () => {
        setIsDiscountModalOpen(true);
    };

    const handleCloseDiscountModal = () => {
        setIsDiscountModalOpen(false);
    };

    const handleApplyDiscount = (code, details) => {
        // console.log('Código de descuento:', code);
        // console.log('Detalles del descuento:', details.coupon_details);
        aplicarDescuento(details.coupon_details); // Llama a la función aplicarDescuento pasada desde el componente principal
        setDiscountApplied(true); // Marca el descuento como aplicado
        setDiscountDetails(details.coupon_details); // Guarda los detalles del descuento
    };

    return (
        <div className=''>
            <DiscountModal
                isOpen={isDiscountModalOpen}
                onClose={handleCloseDiscountModal}
                onApply={handleApplyDiscount}
                carstore={carts}
            />
            <div className='h-screen pt-2'>
                <div className='text-center mt-0 lg:text-left lg:flex lg:pl-5 mt-2'>
                    <h2 className='text-[#1e3d8b] text-lg lg:text-2xl'>ELIGE TU MÉTODO </h2>
                    <h1 className='text-[#030303] font-black text-xl lg:text-2xl lg:ml-2'>DE PAGO</h1>
                </div>
                <Metodopago
                    onMetodosleecChange={onMetodosleecChange}
                    onTarjetaSeleccionadaChange={onTarjetaSeleccionadaChange}
                    totalApagar={totalpedido}
                    otrometodo={otrometodo}
                    cart={carts}
                    enviarPago={enviarPago}
                />
            </div>

            <div className="flex flex-col justify-center lg:w-4/5 bg-white border-t-2 border-gray-300 fixed bottom-0 w-full py-2">
                <div>
                    <div className="flex items-center justify-between w-80 mx-auto lg:hidden ">
                        <p className="text-[#757575] text-sm mr-2">Productos:</p>
                        <h2 className="text-[#012a45] text-sm">
                            ${totalDescuento?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </h2>
                    </div>
                    <div className="flex items-center justify-between w-80 mx-auto mt-1 lg:hidden">
                        <p className="text-[#757575] text-sm mr-2">Envio:</p>
                        <h2 className="text-[#030303] text-sm">
                            {totalenvio > 0
                                ? `$${totalenvio.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                : 'Envío Gratis'
                            }
                        </h2>
                    </div>

                    <div className="flex items-center justify-between w-80 mx-auto mt-2 lg:hidden">
                        <p className="text-[#757575] text-md mr-2">Total:</p>
                        <h2 className="text-[#012a45] text-lg font-semibold">
                            ${totalpedido?.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </h2>
                    </div>

                    <div className='my-2 flex justify-center '>
                        {discountApplied ? (
                            <div className="flex items-center justify-between w-80 mx-auto lg:hidden">
                                <p className="text-green-600 text-md mr-2">Descuento <span className='font-semibold'>{discountDetails?.nombre}</span>:</p>
                                <h2 className="text-green-600 text-lg font-semibold">
                                    {discountDetails?.des_cantidad !== 0
                                        ? `$${discountDetails.des_cantidad}`
                                        : `${discountDetails?.des_porcentaje}%`
                                    }
                                </h2>
                            </div>
                        ) : (
                            !apagarDescuento && (
                                <button
                                    className='text-blue-500 font-black'
                                    onClick={handleOpenDiscountModal}
                                >
                                    <i className="fas fa-tags"></i> Aplicar Código de Descuento
                                </button> 
                            )
                        )}
                    </div>
                </div>

                <div className='flex justify-center mt-2'>
                    <button
                        name='btn-handlePayment'
                        id='btn-handlePayment'
                        onClick={handlePayment}
                        disabled={!nuevoMetodo} // El botón se deshabilita si nuevoMetodo es nulo o vacío
                        className={`py-2 px-10 text-black uppercase rounded font-bold font-roboto transition-colors duration-300 ${nuevoMetodo ? 'bg-[#e9bf56] hover:bg-[#1e3d8b] hover:text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            }`} // Aplicamos clases condicionales para el estilo del botón
                    >
                        {otrometodo ? 'Cambiar método de pago' : 'Continuar'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PaginaFinal;
