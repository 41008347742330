import React, { useEffect, useState } from 'react';
import Header from '../../componentes/Headers/Header';
import PackageTimeline from '../../componentes/pagos/PackageStatus ';
import Resumnpagoslist from '../../componentes/pagos/Resumnpagoslist';
import Footer from '../../componentes/footer/Footer';
import InfoPedido from '../../componentes/pedidos/InfoPedido';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Modalconfirm from '../../componentes/modales/Modalconfirm';
import axios from 'axios';
import { API_URL } from '../../constants/constants';
// import { viewSingleOrder } from '../../utils/PixelMeta/metaPixelEvents';
import MoonLoader from "react-spinners/MoonLoader";
import CargandoSpinner from '../../componentes/cargacomponetes/CargandoSpinner';



function DetalleDelPedido() {
    const { idpedido } = useParams(); // Obtiene el id_pedido de la URL
    const location = useLocation();
    const pedidodatos = location.state.pedido;
    console.log('infopedido', pedidodatos)  //datos que tiene el pediudo aqui se ven 
    const token = localStorage.getItem('k-9SMD879na89dN87m') ? JSON.parse(localStorage.getItem('k-9SMD879na89dN87m')) : null;
    const navigate = useNavigate();

    const [errorStamp, setErrorStamp] = useState({
        "error": "",
        "message": ""
    })

    //datos que se extraen de tracking
    const [tracking, setTracking] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Nuevo estado para controlar la carga

    useEffect(() => {
        window.scrollTo(0, 0);

        const handleBackButtonClick = (event) => {
            event.preventDefault();
            navigate('/Pedidos');
        };

        window.addEventListener('popstate', handleBackButtonClick);
        return () => window.removeEventListener('popstate', handleBackButtonClick);
    }, [navigate]);


    useEffect(() => {
        // viewSingleOrder(token ? token['$email'] : 'No registrado');
        if (pedidodatos.tracking_number) {
            setIsLoading(true); // Inicia el spinner antes de la llamada API
            handleTrackingNumber(pedidodatos.tracking_number);
        }
    }, [pedidodatos.tracking_number]);


    const handleTrackingNumber = async (trackingNumber) => {
        //  console.log(`Tiene tracking number, iré a endpoint de castores: ${trackingNumber}`);
        try {
            const config = {
                headers: {
                    'Authorization': token['$tk3m09s']
                }
            };
            const data = {
                tracking_numbers: String(trackingNumber)
            };
            let response = await axios.post(API_URL + "/checkTrackShipping", data, config);
            const shipmentData = response.data.data.shipments[0];
            const extractedData = {
                tracking_number: shipmentData.tracking_number,
                shipment_history: shipmentData.shipment_history.map(history => ({
                    status: history.status,
                    detail: history.detail,
                    created_at: history.created_at
                })),
                seentregara: shipmentData.seentregara,
                serecogera: shipmentData.serecogera,
                nomdestino: shipmentData.nomdestino,
                teldestino: shipmentData.teldestino,
                addressDest: shipmentData.addressDest,
                fecha: shipmentData.fecha,
                fecha_estatus: shipmentData.fecha_estatus,
                no_guia: shipmentData.no_guia
            };
            //   console.log('esto vopy a mansdar ', extractedData)
            setTracking(extractedData);
        } catch (error) {
            console.error('Ha ocurrido un error al obtener direcciones: ' + error.toString());
            if (error.response.data.error == 'refresh') {
                localStorage.removeItem('k-9SMD879na89dN87m');
                navigate('/');
                return;
            }
        } finally {
            setIsLoading(false); // Detiene el spinner después de la llamada API
        }
    };


    //ejemplo de la consulta al carrito cuando el id edel carrito sea igual al id que viene de la pagina carrito 
    const fechaISO = new Date(pedidodatos.created_at);
    const opciones = { day: 'numeric', month: 'long', year: 'numeric' };
    let fechaLegible = fechaISO.toLocaleDateString("es-MX", opciones);

    // Ajuste manual para el formato específico deseado
    fechaLegible = fechaLegible.replace('de', '').replace('  ', ' del ');

    let pedido = {
        idPedido: pedidodatos.id_pedido,
        idUsuarioPedido: pedidodatos.cliente_ref,
        fechaPedido: fechaLegible,
        costoEnvio: pedidodatos.total_envio,
        estadoPedido: pedidodatos.estado_orden,
        totalPedido: pedidodatos.total,
        estadoPago: pedidodatos.estado_pago,
        totalproductos: pedidodatos.total_productos,
        metodopago: pedidodatos.metodo_pago
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const cancelarPedido = () => {
        // console.log('Se va a cancelar tu ')
        handleOpenModal();

    }

    const funcioncancelarpedido = async () => {
        // console.log('Vamos a empezar a cnacelar padrino ahora si de a debes eh ya no hay vuelta atras ', token)
        let tokenValue = token ? token['$tk3m09s'] : null;


        try {
            const config = {
                headers: {
                    'Authorization': token['$tk3m09s']
                },

                timeout: 10000
            };

            const formdata = {
                'id_pedido': idpedido
            }


            let response = await axios.post(API_URL + '/changeStatusOrder', formdata, config);

            //    console.log(response.data)

            //    console.log(response.data)
        } catch (error) {
            console.error("Error al realizar la petición a la API:", error);

            if (error.code === 'ECONNABORTED') {
                console.log('Request timed out changeStatusOrder');
            }
        }

        handleCloseModal()
    }

    const [loading, setLoading] = useState(false)
    //btna deacarga factura 
    const downloadPdf = async (invoice_id) => {

        // try {
        //     setLoading(true)
        //     const response = await fetch(`${API_URL}/odoo/checkStamp`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': token['$tk3m09s']                    
        //         },
        //         body: JSON.stringify({ "invoice_id": invoice_id})
        //     });
        //     // console.log(await response.json())
        //     let result = await response.json()
        //     setErrorStamp({error: result?.error, message: result?.message})

        //     if(result?.error){
        //         setErrorStamp({error: result?.error, message: result?.message})
        //     }

        //     if (!response.ok) {
        //         console.log(response)
        //         throw new Error('Network response was not ok');
        //     }
        // } catch (error) {
        //     console.log("Error de timbrado")
        //     console.log(error)
        // }

        try {
            setLoading(true)
            const response = await fetch(`${API_URL}/odoo/getPDFInvoice/${invoice_id}`, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Obtiene el blob del PDF
            const blob = await response.blob();

            // Crea un enlace virtual para descargar el archivo
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `factura_${invoice_id}.pdf`); // Usa el invoice_id en el nombre
            document.body.appendChild(link);
            link.click();

            // Limpia el recurso cuando ya no es necesario
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        } finally {
            setLoading(false)
        }
    };

    const [horasOrden, setHorasOrden] = useState(0)
    useEffect(() => {
        const calcularTiempoTranscurrido = () => {
            // Convertir la hora del pedido de UTC a la hora del Centro de México
            const horaPedidoUTC = new Date(pedidodatos.created_at);
            const horaPedidoCDMX = new Date(horaPedidoUTC.toLocaleString("en-US", { timeZone: "America/Mexico_City" }));

            // Calcular la diferencia de tiempo en milisegundos
            const diferenciaTiempo = new Date() - horaPedidoCDMX;

            // Convertir la diferencia de tiempo a horas
            const horasTranscurridas = Math.floor(diferenciaTiempo / (1000 * 60 * 60));

            return horasTranscurridas;
        };

        const horasTranscurridas = calcularTiempoTranscurrido();
        setHorasOrden(horasTranscurridas)
        //   console.log("Horas transcurridas desde el pedido:", horasTranscurridas);
    }, [pedidodatos]);




    const gopagos = (idPedido) => {
        navigate(`/otro-Metodo-de-Pago/${idPedido}`);

    };
    const recuperarpago = (idPedido) => {
        console.log('Se va a recueperar el pago ', pedidodatos)

        const datos_orden = {
            cliente: pedidodatos.cliente,
            datos_cupon: pedidodatos.datos_cupon,
            email: pedidodatos.email,
            envio_castores: pedidodatos.envio_castores,
            factura: pedidodatos.factura,
            gap: pedidodatos.gap,
            mensualpay: pedidodatos.mensualpay,
            msi: parseFloat(pedidodatos.msi),
            msitiene: pedidodatos.msitiene,
            ocurre: pedidodatos.ocurre,
            total: pedidodatos.total,
            total_envio: pedidodatos.total_envio,
            total_productos: pedidodatos.total_productos,
        };

        const datos_completos_orden = {
            cant_motores: pedidodatos.cant_motores,
            cliente: pedidodatos.cliente,
            datos_orden: datos_orden,
            direccion_fiscal: pedidodatos.direccion_fiscal,
            direccion_usuario: pedidodatos.direccion_entrega,
            id_usuario: token['$udi09345'],
            metodo_pago: pedidodatos.metodo_pago,
            office_id: pedidodatos.id_office,
            office_name: pedidodatos.nombreoficina,
            pk: pedidodatos.id_pedido,
            vcio: pedidodatos.updated_at,
            precio_envio: pedidodatos.p_envio,
            sale_order_id: pedidodatos.no_orden_odoo,
            tipo_envio: pedidodatos.t_envio,
            estado_orden: pedidodatos.estado_orden,
            estado_pago: pedidodatos.estado_pago,
            link_pago: pedidodatos.link_pago,
            carrito_usuario: pedidodatos.productos


        };

        //console.log('Datos que enviare para recuperar el pedieoe ', datos_completos_orden);
        if (pedidodatos.metodo_pago === 'credito' || pedidodatos.metodo_pago === 'debito') {
            navigate('/pago-tarjeta', {
                state: {
                    token: pedidodatos.metodo_pago,
                    id: idPedido,
                    datospadso: datos_completos_orden
                }
            });
        } else if (pedidodatos.metodo_pago === 'oxxo') {
            navigate(`/Pago-Oxxo/${idPedido}`, {
                state: {
                    token: pedidodatos.metodo_pago,
                    id: idPedido,
                    datospadso: datos_completos_orden
                }
            });
        } else if (pedidodatos.metodo_pago === 'transferencia') {
            navigate(`/pago-tranferencia/${idPedido}`, {
                state: {
                    token: pedidodatos.metodo_pago,
                    id: idPedido,
                    datospadso: datos_completos_orden
                }
            });
        } else if (pedidodatos.link_pago.startsWith("https://www.mercadopago.com.mx")) {
            window.location.href = pedidodatos.link_pago;
        }
    };

    return (
        <div >
            <Header />
            {isLoading ? (<div className="flex justify-center items-center h-screen">
                <CargandoSpinner txtcharge="Cargando pedido" />
            </div>

            ) : (
                <div>
                    <div className='lg:flex  lg:px-5  xl:w-4/5 lg:mx-auto mt-4 lg:mt-10 '>
                        <div className='lg:w-1/5  xl:pt-6'>
                            <div className='mt-5 xl:ml-2  text-xl uppercase font-black border-b-2 border-gray-300 xl:text-xl lg:text-base  ml-5 pb-1 lg:border-b-0'>
                                <h2><span className='text-[#030303] '>Pedido: </span> <span className='text-[#1e3d8b]'>{pedidodatos.no_orden}</span></h2>

                            </div>

                            <div className='text-left mt-4 px-5 xl:px-2 xl:flex lg:mt-0 '>
                                <h2 className='text-[#030303] font-black text-lg lg:text-base uppercase xl:text-xl'>Estado</h2>
                                <h1 className='text-[#1e3d8b] font-black text-lg lg:text-base uppercase xl:ml-1 xl:text-xl'>Del pedido</h1>
                            </div>

                            <PackageTimeline packageStatus={pedidodatos.estado_orden} datecrate={pedidodatos.created_at} updatedate={pedidodatos.updated_at} dtotracking={tracking} />
                        </div>

                        <div className='lg:ml-1 lg:w-2/5'>

                            <Resumnpagoslist listaCar={pedidodatos.productos} />
                        </div>


                        <div className='lg:ml-1 lg:w-2/5 px-4 lg:px-0  '>

                            <div className='mt-10   px-5  lg:px-2 mx-auto'>
                                {/* <h2 className='text-[#030303] font-black text-2xl lg:text-base xl:text-xl'>INFORMACIÓN</h2>
                                <h2 className='text-[#1e3d8b] font-black text-base xl:text-xl xl:ml-1'>DEL PEDIDO</h2> */}
                                <p className="font-bold text-lg mb-2">INFORMACIÓN <span className='text-[#1e3d8b]'>  DEL PEDIDO</span></p>

                            </div>

                            <InfoPedido pedido={pedido} canceloedido={cancelarPedido} dtosDireccion={pedidodatos.direccion_entrega} />

                            {
                                pedidodatos.id_factura && pedidodatos.id_factura[0] && pedidodatos.id_factura[0].id ? (
                                    <div className='flex justify-center mt-3'>
                                        {loading
                                            ? (
                                                <div className="flex justify-center items-center m-5">
                                                    {/* <!-- Spinner --> */}
                                                    <MoonLoader color="#1A2869"
                                                        size={50}
                                                        speedMultiplier={1}
                                                        width={2.5}
                                                    />
                                                </div>
                                            )
                                            : (<div className='flex flex-col'>
                                                <button
                                                    name='btn-dowunloadfacture'
                                                    id='btn-dowunloadfacture'
                                                    onClick={() => downloadPdf(pedidodatos.id_factura[0].id)} className='w-full bg-[#1e3d8b] px-4 py-2 rounded text-white mt-4 hover:bg-[#007bfc]'>
                                                    Descargar factura
                                                </button>
                                                {/* <p className='text-sm text-red-600 mt-5'>{errorStamp.error}</p>
                                            <div className="text-sm text-red-600" dangerouslySetInnerHTML={{ __html: errorStamp.message }}></div> */}
                                            </div>
                                            )

                                        }
                                    </div>
                                ) : null
                            }

                            {pedidodatos.estado_pago === "Pendiente" && pedidodatos.link_pago && (
                                <div className='flex flex-col items-center justify-center mt-5'>
                                    <p className='text-center text-sm font-semibold text-blue-500 mt-2'>
                                        Ya tienes un pago en proceso. <button onClick={() => recuperarpago(pedidodatos.no_orden)} className="text-blue-700 underline">Recuperar pago</button>
                                    </p>
                                    <button
                                        name='btn-nvometodopago'
                                        id='btn-nvometodopago'
                                        onClick={() => gopagos(pedidodatos.no_orden)}
                                        className="bg-[#e9bf56] text-black font-bold py-2 px-4 rounded mt-5"
                                    >
                                        Nuevo método de pago
                                    </button>

                                </div>
                            )}

                        </div>


                    </div>

                    <Modalconfirm isOpen={isModalOpen} onClose={handleCloseModal} cancelconfirm={funcioncancelarpedido} title='Cancelar Pedido'
                        contentmodal="Cancelar este pedido eliminará todos los artículos asociados con él y esta acción no se puede deshacer. Si hay algún pago realizado, se iniciará el proceso de reembolso según nuestras políticas de devolución. Por favor, confirma si deseas proceder con la cancelación del pedido."
                        txtbtncnacel='Cancelar pedido'
                        txtmantenr='Mantener pedio'
                    />
                </div>

            )}
            <Footer />
        </div>

    );
}

export default DetalleDelPedido;
